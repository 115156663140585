<template>
  <app-list-view
    server-side
    app="chart_of_account"
    model="chartofaccountheader"
    api-url="chart-of-account/chart-of-account-header/"
    :title="$t('menu.chartOfAccount')"
    :createTo="{ name: 'chartOfAccountCreate' }"
    :editTo="{ name: 'chartOfAccountEdit' }"
    :headers="headers"
  >
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'chartOfAccountList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        { text: this.$t('fields.name'), value: 'name' },
        { text: this.$t('fields.company'), value: 'contact_id.name' },
        { text: this.$t('fields.period'), value: 'period.name' },
        { text: this.$t('fields.startDate'), value: 'startDate' },
        { text: this.$t('fields.endDate'), value: 'endDate' },
        { text: this.$t('fields.state'), value: 'state' },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'chartOfAccount'
    })
  }
}
</script>
